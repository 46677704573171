import axios from 'axios'

function getTrendingListing() {
  return axios.get('trending/list/all')
}

function getTrendingById(id) {
  return axios.get(`trending/${id}`)
}

function editTrending(id, payload) {
  return axios.put(`trending/${id}`, payload)
}

export default {
  getTrendingListing,
  getTrendingById,
  editTrending
}
