<template>
  <div>
    <div v-if="!items.length && !contentLoading" class="demo-spacing-0">
      <b-alert variant="primary" show>
        <div class="alert-body">
          <span><strong>{{ NotFound.TRENDING_NOT_FOUND }}</strong></span>
        </div>
      </b-alert>
    </div>
    <Loader v-if="contentLoading" />
    <div>
      <transition-group class="row">
        <div v-for="(item, idx) in items" :key="idx" class="col-md-4">
          <div class="card trending-card trending_card_box">
            <img :src="item.trendingMedia" alt="trending-image">
            <span class="trending-name">{{
              item.trendingLanguages[0].title
            }}</span>
            <b-dropdown
              style="position: absolute; bottom: 0; right: 0;"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  stroke="white"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="
                  checkLoginRole() === RoleEnum.SuperAdmin
                    ? true
                    : checkAccess.Edit
                "
                :to="{ name: 'trending-now-edit', params: { id: item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BAlert } from "bootstrap-vue";
import { RoleEnum, resConditionCheck, NotFound } from "@/helpers/constant";
// import accessRightCheck from '@/helpers/accessRightCheck'
import checkLoginRole from "@/helpers/checkLoginRole";
import TrendingService from "@/services/banner-management/trending/trending.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from "@/services/errorHandler";
import Loader from "@/layouts/skeloton-loader/Loader.vue";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BAlert,
    Loader
  },

  data() {
    return {
      contentLoading: true,
      totalTrendings: 0,
      items: [],
      arrList: [],
      startIndex: 0,
      endIndex: 0,
      tableColumns: [{ key: "order" }, { key: "trending" }, { key: "actions" }],
      resConditionCheck,
      // checkAccess: {},
      RoleEnum,
      NotFound,
      checkLoginRole
    };
  },

  mounted() {
    // this.checkAccess = accessRightCheck(this.$route.meta.module.name)
    this.getTrendingListing();
  },

  methods: {
    getTrendingListing() {
      this.contentLoading = true
      TrendingService.getTrendingListing()
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data;
            this.totalTrendings = res.data.count;
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          if (error.response.status === 404) {
            this.items = [];
            this.totalTrendings = 0;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.trending-card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 180px;
  position: relative;
  width: 100%;
  margin: 0 0 30px 0;
}
.trending-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.trending-card .trending-name {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  font-size: 14px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
